import React, { useState } from 'react'
import styled from 'styled-components'
import Button from 'components/Button'
import { useStaticQuery, graphql } from 'gatsby'
import { subscribe } from 'api'
import { emailIsValid } from 'src/helpers'
import RichTextRenderer from './components/RichTextRenderer'

const subscribeBoxQuery = graphql`
  query subscribeBoxQuery {
    allContentfulSubscribeBox(limit: 1) {
      edges {
        node {
          text {
            json
          }
          buttonText
        }
      }
    }
  }
`

const Box = styled.div`
  box-sizing: border-box;
  padding: 20px 20px;
  min-height: 240px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.accent};
`

const ThankYou = styled.div`
  font-weight: bold;
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  visibility: ${({ hide }) => (hide ? 'hidden' : 'visible')};
  position: ${({ hide }) => (hide ? 'absolute' : 'relative')};
  transition: opacity 300ms;
`

const Content = styled.div`
  width: 100%;
  height: 100%;
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  visibility: ${({ hide }) => (hide ? 'hidden' : 'visible')};
  position: ${({ hide }) => (hide ? 'absolute' : 'relative')};
  transition: opacity 300ms;

  > ul,
  > li {
    margin: 0;
    > div {
      line-height: 16px;
    }
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
`

const EmailInput = styled.input`
  flex: 1;
  box-sizing: border-box;
  width: 100%;
  padding: 7px 10px;
  margin-right: 10px;
  border: 1px solid #7addb6;
  border-radius: 5px;
  font-size: 16px;

  ::placeholder {
    color: #bebebe;
  }

  :focus,
  :active {
    outline: #7addb6 auto 5px;
  }
`

const ErrorText = styled.div`
  padding-top: 6px;
  color: red;
  font-size: 14px;
  font-weight: italic;
`

const SubscribeBox = ({ className }) => {
  const queryData = useStaticQuery(subscribeBoxQuery)
  const [email, setEmail] = useState('')
  const [showInvalidEmailWarning, setShowInvalidEmailWarning] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showFailure, setShowFailure] = useState(false)
  const [isSending, setIsSending] = useState(false)
  return (
    <Box className={className}>
      <ThankYou hide={!showSuccess}>Thank you for subscribing!</ThankYou>
      <Content hide={showSuccess}>
        <RichTextRenderer
          richText={
            queryData.allContentfulSubscribeBox?.edges[0].node.text?.json
          }
        />
        <Form
          onSubmit={async (e) => {
            e.preventDefault()
            if (!emailIsValid(email)) {
              setShowInvalidEmailWarning(true)
              return
            }
            setIsSending(true)
            const res = await subscribe(email)
            setIsSending(false)
            if (res.ok) {
              setShowSuccess(true)
            } else {
              setShowFailure(true)
            }
          }}
        >
          <EmailInput
            type="text"
            placeholder="Your email"
            value={email}
            onChange={(e) => {
              setShowSuccess(false)
              setShowFailure(false)
              if (emailIsValid(email)) {
                setShowInvalidEmailWarning(false)
              }
              setEmail(e.target.value)
            }}
            ontouchstart=""
          />
          <Button type="submit" disabled={isSending}>
            {queryData.allContentfulSubscribeBox?.edges[0].node?.buttonText}
          </Button>
        </Form>
        {showInvalidEmailWarning && (
          <ErrorText>Please enter a valid email</ErrorText>
        )}
        {showFailure && <ErrorText>Something went wrong!</ErrorText>}
      </Content>
    </Box>
  )
}

export default SubscribeBox
