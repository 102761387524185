import React from 'react'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import styled from 'styled-components'
import OutboundLink from 'components/OutboundLink'

const StyledText = styled.div`
  white-space: pre-line;
  margin-block-start: 8px;
  margin-block-end: 8px;

  :empty,
  > b:empty {
    display: none;
  }

  :empty:before,
  > b:empty:before {
    content: ' ';
    white-space: pre;
  }
`

const StyledH4 = styled.h4`
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 20px;
`

export const richTextOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <StyledText>{children}</StyledText>,
    [BLOCKS.HEADING_3]: (node, children) => (
      <StyledH4 data-title={node.content[0]?.value}>{children}</StyledH4>
    ),
    [INLINES.HYPERLINK]: (node) => {
      if (node.data.uri.startsWith('https://buildingremotely.com')) {
        return (
          <OutboundLink href={node.data.uri}>
            {node.content[0].value}
          </OutboundLink>
        )
      }
      return (
        <OutboundLink
          href={node.data.uri}
          target="_blank"
          rel="noopener noreferrer"
        >
          {node.content[0].value}
        </OutboundLink>
      )
    },
  },
}

export default ({ richText }) =>
  documentToReactComponents(richText, richTextOptions)
