import React from 'react'
import styled from 'styled-components'
import { sizes } from 'styles'

const StyledPageTitle = styled.h1`
  margin: 0;
  margin-bottom: 30px;
  color: ${({ theme }) => theme.color.textSecondary};
  font-size: 40px;
  font-family: ${({ theme }) => theme.font.text};

  @media ${sizes.tabletPortrait} {
    font-size: 50px;
  }

  @media ${sizes.desktop} {
    font-size: 60px;
    line-height: 64px;
  }
`

const PageTitle = ({ children, className }) => (
  <StyledPageTitle className={className}>{children}</StyledPageTitle>
)

export default PageTitle
