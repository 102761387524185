import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { graphql } from 'gatsby'
import { useSpring, animated } from 'react-spring'
import StickToScroll from 'components/StickToScroll'
import RichTextRenderer from 'components/RichTextRenderer'
import Container from 'components/Container'
import Seo from 'components/Seo'
import PageTitle from 'components/PageTitle'
import SubscribeBox from 'components/SubscribeBox'
import Subscribe from 'components/Subscribe'
import AboutImg from 'images/about.png'
import { sizes } from 'styles'
import { FOOTER_HEIGHT } from 'src/consts'

const AboutPage = styled.div`
  position: relative;
`

const Columns = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    flex: 1;
  }

  @media ${sizes.tabletLandscape} {
    flex-direction: row;
    padding-bottom: 40px;
  }
`

const RightColumn = styled.div`
  @media ${sizes.tabletLandscape} {
    box-sizing: border-box;
    max-width: 47vw;
    padding: 120px 20px 0 40px;
  }

  @media ${sizes.desktop} {
    padding: 100px;
  }
`

const StyledSubscribeBox = styled(SubscribeBox)`
  width: 100%;

  @media ${sizes.tabletLandscape} {
    margin-top: 60px;
    width: 375px;
  }
`

const ImageContainer = styled(animated.div)`
  width: 40vw;
  max-width: 700px;
  height: auto;
  display: none;

  @media ${sizes.tabletLandscape} {
    display: block;
  }
`

const ImageStickToScroll = styled(StickToScroll)`
  right: 0;
`

const Image = styled.img`
  width: 100%;
  height: auto;
`

const ImageAndFeedback = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media ${sizes.tabletPortrait} {
    flex-direction: row;

    > :first-child {
      flex: 1;
      margin-top: 30px;
    }
  }

  @media ${sizes.tabletLandscape} {
    > :first-child {
      flex: initial;
    }
  }
`

const MobileImage = styled(Image)`
  display: block;
  width: 75vw;
  margin-left: auto;
  margin-right: -20px;

  @media ${sizes.tabletPortrait} {
    width: 40vw;
    margin-right: -40px;
    padding-left: 30px;
  }

  @media ${sizes.tabletLandscape} {
    display: none;
  }
`

const SubscribeHeading = styled.h4`
  margin: 0;
  margin-bottom: 10px;
`

const StyledSubscribe = styled(Subscribe)`
  display: none;

  @media ${sizes.tabletLandscape} {
    display: block;
  }
`

const About = ({ data }) => {
  const { allContentfulAboutPage } = data
  const { title, content } = allContentfulAboutPage.edges[0].node
  const isLeavingPage = useSelector((state) => state.isLeavingPage)

  const [props, animate] = useSpring(() => ({
    delay: 300,
    opacity: 1,
    transform: 'translate3d(0%, 0px, 0px)',
    from: { opacity: 0, transform: 'translate3d(10%, 0px, 0px)' },
  }))

  useEffect(() => {
    if (isLeavingPage) {
      animate.start({
        delay: 0,
        opacity: 0,
        transform: 'translate3d(10%, 0px, 0px)',
      })
      return
    }
    animate.start({
      delay: 300,
      opacity: 1,
      transform: 'translate3d(0%, 0px, 0px)',
    })
  }, [isLeavingPage])

  return (
    <AboutPage>
      <Container>
        <Seo
          title="About"
          image={{ src: undefined, width: 1600, height: 900 }}
          description={undefined}
        />
        <Columns>
          <div>
            <PageTitle>{title}</PageTitle>
            <RichTextRenderer richText={content.json} />
            <ImageAndFeedback>
              <StyledSubscribeBox />
              <MobileImage src={AboutImg} alt="spaceship" />
            </ImageAndFeedback>
          </div>
          <RightColumn>
            <StyledSubscribe>
              <SubscribeHeading>Subscribe for updates</SubscribeHeading>
            </StyledSubscribe>
          </RightColumn>
        </Columns>
      </Container>
      <ImageStickToScroll fromBottom bound={FOOTER_HEIGHT}>
        <ImageContainer style={props}>
          <Image src={AboutImg} alt="spaceship" />
        </ImageContainer>
      </ImageStickToScroll>
    </AboutPage>
  )
}

export const pageQuery = graphql`
  query AboutPageQuery {
    allContentfulAboutPage {
      edges {
        node {
          title
          content {
            json
          }
        }
      }
    }
  }
`

export default About
